import {Select, MenuItem, InputLabel} from "@mui/material"

type Props = {
  onChange?: (val: string) => void
  selected: string
  products: Array<{id: string; name: string}>
  label: string
}

export function ProductSelect({label, onChange, selected, products}: Props) {
  return (
    <>
      <InputLabel>{label}</InputLabel>
      {products.length > 0 ? (
        <>
          <Select
            placeholder="Enter a parent product"
            fullWidth
            value={selected}
            onChange={
              onChange != null
                ? (e) => {
                    onChange(e.target.value as string)
                  }
                : undefined
            }
            disabled={onChange == null}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {products.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : null}
    </>
  )
}
