import {useEffect, useState} from "react"

import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material"
import moment from "moment"
import * as R from "ramda"
import {FieldSpec, Values} from "src/components/filters/TableFilters"
import HighlightedText from "src/components/HighlightedText"
import Page from "src/components/Page"
import ResponsiveTable, {Column} from "src/components/ResponsiveTable"
import {useUserPermission} from "src/features/auth/hooks/useUserPermissions"
import {getTicketTypesHash} from "src/features/tickets/pages/TicketPage"
import {VIEW_TICKETS_ONLY} from "src/features/users/components/UserPermissionsWidget"
import withBusiness from "src/features/withBusiness"
import {removeNothings} from "src/utils/nothingRemoval"
import {
  Permission,
  TicketPaymentStatus,
  TicketPaymentType,
  TicketReviewStatus,
  TicketSearchResultsQuery,
  TicketSearchResultsQueryVariables,
  useTicketProductsForBusinessQuery,
  useTicketSearchResultsQuery,
} from "../../../types/apollo"
import useDebounce from "../../checkIns/utils/useDebounce"
import withAuth from "../../withAuth"
import TicketStatusBadge from "../components/TicketStatusBadge"
import getTicketTypeTicketVisitorsDisplay from "../helpers/getTicketTypeTicketVisitorsDisplay"
import Case from "case"

const PAGE_SIZE = 20
const SEARCH_TERM_DELAY = 500

const DEFAULT_PAYMENT_STATUSES = [
  TicketPaymentStatus.Paid,
  TicketPaymentStatus.Refunded,
  TicketPaymentStatus.Failed,
]

const DEFAULT_REVIEW_STATUSES: Array<TicketReviewStatus> = []

function TicketSearchPage() {
  const createTicketPermission = useUserPermission(
    Permission.CmsCanCreateTickets,
  )
  const [options, setOptions] = useState({
    searchTerm: sessionStorage.getItem("ticketsSearch_searchTerm"),
    paymentStatuses:
      (sessionStorage
        .getItem("ticketsSearch_paymentStatuses")
        ?.split(",") as Array<TicketPaymentStatus> | null) ??
      DEFAULT_PAYMENT_STATUSES,
    reviewStatuses:
      (sessionStorage
        .getItem("ticketsSearch_reviewStatuses")
        ?.split(",") as Array<TicketReviewStatus> | null) ??
      DEFAULT_REVIEW_STATUSES,
    productId: null as string | null,
  })
  const {searchTerm, paymentStatuses, reviewStatuses, productId} = options

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_TERM_DELAY)

  useEffect(() => {
    if (
      !options.paymentStatuses.every((s) =>
        Object.values(TicketPaymentStatus).includes(s),
      )
    ) {
      setOptions((o) => ({...o, paymentStatuses: DEFAULT_PAYMENT_STATUSES}))
    }
    if (
      !options.reviewStatuses.every((s) =>
        Object.values(TicketReviewStatus).includes(s),
      )
    ) {
      setOptions((o) => ({...o, reviewStatuses: DEFAULT_REVIEW_STATUSES}))
    }
  }, [options.paymentStatuses, options.reviewStatuses])

  const {
    data: ticketProductsForBusinessData,
    loading: ticketProductsForBusinessLoading,
    error: ticketProductsForBusinessError,
  } = useTicketProductsForBusinessQuery()

  const variables: TicketSearchResultsQueryVariables = {
    pagination: {
      limit: PAGE_SIZE,
    },
    filters: {
      searchTerm: debouncedSearchTerm,
      productId,
      reviewStatuses: reviewStatuses.length > 0 ? reviewStatuses : null,
      paymentStatuses: paymentStatuses.length > 0 ? paymentStatuses : null,
    },
  }

  const {data, networkStatus, fetchMore} = useTicketSearchResultsQuery({
    variables,
  })

  useEffect(() => {
    const products = ticketProductsForBusinessData?.products

    if (products === undefined || !products.length) {
      return
    }

    if (products.length === 1) {
      setOptions((o) => ({...o, productId: products[0].id}))
      sessionStorage.setItem("ticketsSearch_productId", products[0].id)
    } else {
      const productIds = R.pluck("id", products)
      const localStorageSelection = sessionStorage.getItem(
        "ticketsSearch_productId",
      )

      if (localStorageSelection && productIds.includes(localStorageSelection)) {
        setOptions((o) => ({...o, productId: localStorageSelection}))
      } else {
        setOptions((o) => ({...o, productId: null}))
        sessionStorage.removeItem("ticketsSearch_productId")
      }
    }
  }, [ticketProductsForBusinessData])

  if (ticketProductsForBusinessLoading) {
    return <Page loading />
  }

  if (ticketProductsForBusinessError || !ticketProductsForBusinessData) {
    return (
      <Page
        error={
          ticketProductsForBusinessError || "Error whilst loading products"
        }
      />
    )
  }

  const products = ticketProductsForBusinessData.products

  const loading =
    networkStatus === 1 ||
    networkStatus === 2 ||
    searchTerm !== debouncedSearchTerm
  const loadingMore = networkStatus === 3
  const hasMore =
    (data && data.ticketSearch && data.ticketSearch.forwardEdge) || false

  const rows = data?.ticketSearch?.tickets
    ? removeNothings(data?.ticketSearch?.tickets)
    : undefined

  const getTable = () => {
    return (
      <ResponsiveTable
        data={rows}
        emptyMessage={"No tickets for the search criteria"}
        columns={getTicketColumns(searchTerm ?? undefined)}
        loading={loading}
        error={!loading && (!data || !data.ticketSearch.tickets)}
        hasMore={hasMore}
        loadingMore={loadingMore}
        getLink={getLink}
        getKey={getKey}
        dataCy="tickets-search-table"
        filters={{
          spec: {
            reviewStatuses: {
              type: "multi-select",
              options: Object.values(TicketReviewStatus),
              optionLabels: {
                [TicketReviewStatus.Active]: "Active",
                [TicketReviewStatus.Queried]: "Queried",
                [TicketReviewStatus.Invalid]: "Invalid",
              },
            },
            paymentStatuses: {
              type: "multi-select",
              options: Object.values(TicketPaymentStatus),
              optionLabels: {
                [TicketPaymentStatus.Paid]: "Paid",
                [TicketPaymentStatus.Failed]: "Failed",
                [TicketPaymentStatus.Refunded]: "Refunded",
                [TicketPaymentStatus.Unpaid]: "Unpaid",
              },
            },
            productId: {
              type: "select",
              options: products.map((p) => p.id),
              emptyOptionLabel: "All",
              optionLabels: products.reduce((acc, val) => {
                return {
                  ...acc,
                  [val.id]: val.name,
                }
              }, {}),
              hidden: products.length < 2,
            },
          },
          initialValues: options as unknown as Values<{
            [key: string]: FieldSpec
          }>,
          onChange: (k, v) => {
            setOptions({...options, [k]: v})
            saveSearchTermToSessionData(k, v)
          },
        }}
        onRequestLoadMore={() => {
          if (!data || !data.ticketSearch.forwardEdge) {
            return
          }

          fetchMore({
            variables: {
              ...variables,
              pagination: {
                limit: PAGE_SIZE,
                after: data.ticketSearch.forwardEdge,
              },
            },
            updateQuery: (prev, {fetchMoreResult}) => {
              if (
                !fetchMoreResult ||
                !fetchMoreResult.ticketSearch.tickets.length
              ) {
                return prev
              }

              return {
                ticketSearch: {
                  ...fetchMoreResult.ticketSearch,
                  tickets: [
                    ...prev.ticketSearch.tickets,
                    ...fetchMoreResult.ticketSearch.tickets,
                  ],
                },
              }
            },
          })
        }}
      />
    )
  }

  const saveSearchTermToSessionData = (
    key: string | number,
    value: unknown,
  ) => {
    if ((Array.isArray(value) && value.length === 0) || value == null) {
      sessionStorage.removeItem(`ticketsSearch_${key}`)
    } else {
      sessionStorage.setItem(`ticketsSearch_${key}`, value as string)
    }
  }

  return (
    <Page
      title="Ticket Search"
      buttons={
        createTicketPermission
          ? [
              {
                type: "link" as const,
                text: "Create",
                icon: "note_add",
                to: "/tickets/new",
              },
            ]
          : undefined
      }
    >
      <Container maxWidth="lg">
        <Card sx={{marginBottom: 4}}>
          <CardContent
            style={{
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <Box display="flex" flexDirection={["column", "row"]}>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  data-cy="tickets-search-query"
                  value={searchTerm}
                  onChange={(e) => {
                    setOptions({...options, searchTerm: e.target.value})
                    saveSearchTermToSessionData("searchTerm", e.target.value)
                  }}
                  fullWidth
                  placeholder="Search by name, email or customer ID..."
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setOptions({
                              ...options,
                              searchTerm: debouncedSearchTerm,
                            })
                            saveSearchTermToSessionData(
                              "searchTerm",
                              debouncedSearchTerm,
                            )
                          }}
                          size="large"
                        >
                          <Icon>search</Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>
          </CardContent>
        </Card>
        {getTable()}
      </Container>
    </Page>
  )
}

type TicketRow = Pick<
  NonNullable<TicketSearchResultsQuery["ticketSearch"]["tickets"][number]>,
  | "id"
  | "email"
  | "status"
  | "paymentStatus"
  | "ticketTypes"
  | "reviewStatus"
  | "product"
  | "expiresAt"
  | "paymentType"
>
const getLink = (row: TicketRow) => `/tickets/${row.id}`
const getKey = (row: TicketRow) => row.id

export const STATUS: Column<Pick<TicketRow, "status" | "paymentStatus">> = {
  key: "reviewStatuses",
  title: "Status",
  getValue: ({status}) => <TicketStatusBadge status={status} key="status" />,
}

export function visitorsColumn(
  searchTerm?: string,
): Column<Pick<TicketRow, "ticketTypes">> {
  return {
    title: "Visitors (Customer ID)",
    getValue: ({ticketTypes}) => {
      const ticketTypesHash = getTicketTypesHash(ticketTypes)
      const ticketTypesFormatted = Object.values(ticketTypesHash).map(
        ({name, ticketTypes}) => {
          return `${ticketTypes.length}x ${name}`
        },
      )

      const visitors = R.flatten(R.pluck("visitors", ticketTypes))
      const visitorText = [
        ...ticketTypesFormatted,
        ...getTicketTypeTicketVisitorsDisplay(visitors),
      ]
      return visitorText.map((t, i) => (
        <HighlightedText minimumTermLength={1} searchTerm={searchTerm} key={i}>
          {t}
        </HighlightedText>
      ))
    },
  }
}

export const getDetailsColumn = (): Column<{product: {name: string}}> => {
  return {
    key: "productId",
    title: "Details",
    getValue: ({product}) => [product.name],
  }
}

export const EXPIRES_AT: Column<Pick<TicketRow, "expiresAt">> = {
  title: "Expires at",
  getValue: ({expiresAt}) => {
    return moment(expiresAt).format("Do MMMM YYYY HH:mm")
  },
}

export function emailColumn(
  searchTerm?: string,
): Column<Pick<TicketRow, "email">> {
  return {
    title: "Email",
    getValue: ({email}) => {
      return (
        <HighlightedText minimumTermLength={1} searchTerm={searchTerm}>
          {email}
        </HighlightedText>
      )
    },
  }
}

const PAYMENT_STATUS: Column<Pick<TicketRow, "paymentStatus" | "paymentType">> =
  {
    key: "paymentStatuses",
    title: "Payment",
    getValue: ({paymentStatus, paymentType}) => {
      return (
        <>
          <Box
            fontFamily="monospace"
            fontSize="14px"
            fontWeight="bold"
            whiteSpace="preserve"
          >
            {paymentStatus}{" "}
          </Box>
          {paymentType !== TicketPaymentType.NotRequired && (
            <Box fontFamily="monospace" fontSize="14px" whiteSpace="preserve">
              {Case.title(paymentType)}
            </Box>
          )}
        </>
      )
    },
  }

export function getTicketColumns(searchTerm?: string) {
  return [
    STATUS,
    visitorsColumn(searchTerm),
    emailColumn(searchTerm),
    PAYMENT_STATUS,
    EXPIRES_AT,
    getDetailsColumn(),
  ]
}

export default withAuth(VIEW_TICKETS_ONLY)(withBusiness(TicketSearchPage))
