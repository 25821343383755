import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material"
import {useTranslation} from "react-i18next"
import {GetTicketQuery, useCancelSubscriptionMutation} from "src/types/apollo"
import BusyButton from "src/components/BusyButton"
import moment from "moment"

interface Props {
  modalOpen: boolean
  ticket: NonNullable<GetTicketQuery["tickets"][0]>
  toggleModal: () => void
  onSuccess: () => void
  onError: () => void
}

export default function CancelSubscriptionModal({
  modalOpen,
  ticket,
  toggleModal,
  onSuccess,
  onError,
}: Props) {
  const {t} = useTranslation()

  const [cancelSubscription, {loading}] = useCancelSubscriptionMutation()

  const handleSubmit = async () => {
    const {data} = await cancelSubscription({
      variables: {
        ticketId: ticket.id,
      },
      refetchQueries: ["GetTicket"],
      onError: (_apolloError) => {
        onError()
      },
      onCompleted: (result) => {
        if (result.cancelSubscription.id) {
          onSuccess()
        }
      },
    })
    console.log(data)
  }

  return (
    <Dialog onClose={toggleModal} open={modalOpen} fullWidth>
      <DialogTitle>
        {t("features.tickets.subscription.cancel.title")}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            {t("features.tickets.subscription.cancel.explanation")}
          </Typography>
          <Typography>
            {t("features.tickets.subscription.cancel.expires", {
              expiresAt: moment(ticket.expiresAt).format("YYYY-MM-DD"),
            })}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal} color="primary" autoFocus>
          {t("actions.cancel")}
        </Button>
        <BusyButton
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          busy={loading}
        >
          {t("features.tickets.subscription.cancel.submit")}
        </BusyButton>
      </DialogActions>
    </Dialog>
  )
}
