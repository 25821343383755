import {ApolloError} from "@apollo/client"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {Trans, useTranslation} from "react-i18next"
import NumberFormat from "react-number-format"
import BusyButton from "src/components/BusyButton"
import ErrorDisplay from "src/components/ErrorDisplay"
import {
  GetTicketQuery,
  Refund_Ticket_Reason as RefundReason,
} from "src/types/apollo"
import {formatPrice} from "src/utils/formatPrice"

const refundReasons: Array<{name: string; value: RefundReason}> = [
  {name: "Duplicate", value: RefundReason.Duplicate},
  {name: "Fraudulent", value: RefundReason.Fraudulent},
  {name: "Requested by customer", value: RefundReason.RequestedByCustomer},
  {name: "Other", value: RefundReason.Other},
]

type GetTicket_tickets = NonNullable<GetTicketQuery["tickets"][number]>
interface Props {
  modalOpen: boolean
  entity: Pick<GetTicket_tickets, "paymentData">
  toggleModal: () => void
  handleSubmit: () => void
  inputError: string | undefined
  handleAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleReasonChange: (event: SelectChangeEvent) => void
  handleNotesChange: (event: React.ChangeEvent<{value: unknown}>) => void
  setRefundAmount: (amount: string | undefined) => void
  amountPaid: number
  amountRefunded: number
  refundError?: ApolloError
  refundAmount?: string
  refundReason?: string
  notes?: string
  refundLoading: boolean
}

export default function RefundModal({
  modalOpen,
  entity,
  toggleModal,
  handleSubmit,
  handleAmountChange,
  handleReasonChange,
  handleNotesChange,
  setRefundAmount,
  amountPaid,
  amountRefunded,
  inputError,
  refundError,
  refundAmount,
  refundReason,
  notes,
  refundLoading,
}: Props) {
  const {t} = useTranslation()
  const paymentData =
    entity.paymentData?.__typename === "TicketPaymentDataPaymentIntent"
      ? entity.paymentData?.paymentIntent
      : entity.paymentData?.__typename === "TicketPaymentDataPeachPayment"
        ? entity.paymentData?.data
        : null

  if (!paymentData) {
    return null
  }
  const fullyRefunded = amountRefunded === amountPaid

  return (
    <Dialog onClose={toggleModal} open={modalOpen} fullWidth>
      <DialogTitle>{t("features.tickets.refund.payment")}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography color="textSecondary" variant="body2">
            {t("features.tickets.refund.advice")}
          </Typography>
          {fullyRefunded ? (
            <Typography style={{marginRight: 8}}>
              {t("features.tickets.refund.status.fullyRefunded")}
            </Typography>
          ) : (
            <Stack spacing={2}>
              <Typography>
                <Trans>
                  {t("features.tickets.refund.amountPaid", {
                    amount: formatPrice(paymentData.currencyCode, amountPaid),
                    refunded:
                      amountRefunded > 0
                        ? t("features.tickets.refund.amountRefunded", {
                            amount: formatPrice(
                              paymentData.currencyCode,
                              amountRefunded,
                            ),
                          })
                        : undefined,
                  })}
                </Trans>
              </Typography>
              <Box display="flex" alignItems="center" marginBottom={4}>
                <TextField
                  label={t("features.tickets.refund.amount")}
                  value={refundAmount}
                  name="refund-amount"
                  onChange={handleAmountChange}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
                <Button
                  aria-label={t("features.tickets.refund.full")}
                  style={{marginLeft: "1rem"}}
                  onClick={() =>
                    setRefundAmount(`${(amountPaid - amountRefunded) / 100}`)
                  }
                >
                  {t("features.tickets.refund.full")}
                </Button>
              </Box>
              {(refundError || inputError) && (
                <ErrorDisplay>
                  {refundError ? refundError.message : inputError || ""}
                </ErrorDisplay>
              )}

              {entity.paymentData?.__typename ===
                "TicketPaymentDataPaymentIntent" && (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="refund-reason">
                      {t("features.tickets.refund.reason")}
                    </InputLabel>
                    <Select
                      labelId="refund-reason"
                      id="refund-reason"
                      value={refundReason}
                      label={t("features.tickets.refund.reason")}
                      onChange={handleReasonChange}
                    >
                      {refundReasons.map(({name, value}) => (
                        <MenuItem value={value} key={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label={t("features.tickets.refund.notes")}
                    value={notes}
                    name="notes"
                    onChange={handleNotesChange}
                  />
                </>
              )}
            </Stack>
          )}
          <DialogActions>
            <Button onClick={toggleModal} color="primary" autoFocus>
              {t("actions.cancel")}
            </Button>
            <BusyButton
              disabled={fullyRefunded}
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              busy={refundLoading}
            >
              {t("features.tickets.refund.submit")}
            </BusyButton>
          </DialogActions>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

interface NumberFormatCustomProps {
  inputRef: (el: HTMLInputElement) => void
  onChange: (event: {target: {name: string; value: string}}) => void
  name: string
  prefix: string
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const {onChange, inputRef, ...other} = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      prefix={props.prefix}
      thousandSeparator
      isNumericString
      decimalScale={2}
    />
  )
}
